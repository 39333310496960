import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

// Type for collection, as our programs are known as Collections
export enum EventType {
  Signature = 'Signature',
  LimitedEdition = 'LimitedEdition',
  QuarterlyWorkshop = 'QuarterlyWorkshop',
  Innovation = 'Innovation',
}

interface EventTypeContextValue {
  eventType: EventType
  isLimitedEdition: boolean
  isInnovation: boolean
  isSignature: boolean
  setToSignature: () => void
  setToLimitedEdition: () => void
  setToInnovation: () => void
}

const EventTypeContext = createContext<EventTypeContextValue>({
  eventType: EventType.Signature,
  isInnovation: false,
  isLimitedEdition: false,
  isSignature: false,
  setToSignature: () => {},
  setToLimitedEdition: () => {},
  setToInnovation: () => {},
})

export const EventTypeProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [eventType, setEventType] = useState<EventType>(EventType.Signature)
  const setToSignature = () => setEventType(EventType.Signature)
  const setToLimitedEdition = () => setEventType(EventType.LimitedEdition)
  const setToInnovation = () => setEventType(EventType.Innovation)

  return (
    <EventTypeContext.Provider
      value={{
        eventType,
        isLimitedEdition: eventType === EventType.LimitedEdition,
        isInnovation: eventType === EventType.Innovation,
        isSignature: eventType === EventType.Signature,
        setToSignature,
        setToLimitedEdition,
        setToInnovation,
      }}
    >
      {children}
    </EventTypeContext.Provider>
  )
}

export const useEventType = () => {
  const context = useContext(EventTypeContext)
  if (!context) {
    throw new Error('useEventType must be used within a EventTypeProvider')
  }
  return context
}
